.integrations {
  .title-h1{
    margin: 16px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
  .connect-partners-list{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .item{
      display: flex;
      width: 340px;
      padding: 16px 24px;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid var(--Outline-underline, #E8E8E8);
      .item-left{
        display: flex;
        align-items: center;
      }
      .img-logo{
        display: block;
        width: 24px;
        height: 24px;
      }
      .text{
        margin-left: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
.login-pop-up{
  font-size: 16px;
}
.popup-onboarding{
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: column;
  .title-h1{
    font-size: 18px;
    color: #252525;
    font-weight: 600;
  }
  .info, .text{
    font-size: 16px;
    line-height: 22px;
    color: #0d0e10;
  }
  .text{
    color: #616368;
  }
  .error{
    color: #e10013;
    font-size: 16px;
    line-height: 22px;
  }
}
.validating-menu{
  display: flex;
  gap: 8px;
  flex-direction: column;
  .success{
    color: #73D13D;
  }
  .error{
    color: #E10013;
  }
  .icon-status{
    font-weight: 24;
  }
  .blue{
    color: #2563EB;
  }
  .item-menu-status{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 16px;
  }
}
.error-message{
  padding: 8px 16px;
  margin-bottom: 10px;
  background-color: #ffedea;
  font-size: 16px;
  font-weight: 400;
  color: #e10013;
  line-height: 22px;
}
.popup-footer-box{
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}