.net-sales-summary-report {
  min-width: 900px;
  padding-bottom: 22px;
  // background: #F5F5F5;

  .title-wrap {
    height: 57px;
    font-size: 18px;
    background: white;

    .title {
      height: 100%;
      margin: 0 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;

    }
  }

  .content-wrap {
    padding: 22px;
    background: #F5F5F5;
  }

  .filter-wrap {
    display: flex;
    justify-content: space-between;

    .filter-right-wrap {
      display: flex;
      gap: 0 10px;

      .ant-btn {
        border: 0;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
    min-width: 100%;
    margin: 0 -15px;
    .m-title .title{
      padding-left: 15px;
    }
    .filter-wrap{
      position: sticky;
      top: 40px;
      padding: 10px 0;
      background-color: #f5f5f5;
      z-index: 99;
      margin: -10px 0;
      .filter-right-wrap{
        position: fixed;
        top: 60px;
        right: 15px;
        z-index: 10;
      }
    }
    .content-wrap{
      margin-top: -16px;
      padding: 10px;
    }
    .net-sales-summary-comparison-summary{
      margin-top: 0;
      padding: 10px 5px;
      .comparison-summary-header-wrap .comparison-summary-title-wrap{
        padding-left: 10px;
        font-size: 16px;
      }
    }
    .net-sales-summary-legend-wrap{
      padding-left: 10px;
    }
  }
}

// ------- 打印样式 -------
@media print {
  .print-container {
    padding: 20px; 

    .ant-table-wrapper table {
      width: 100% !important;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;

      .ant-empty-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-empty-description {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}