@import "../index.scss";
.modal-body-wrap {
  .ant-modal-content {
    background-color: #f5f5f5;

    .ant-modal-title {
      font-size: 24px;
      background-color: #f5f5f5;
    }

    .ant-modal-body {
      padding-top: 0px !important;
      @include usage-card;
    }
  }
  .menus-usage{
    margin: 15px 0 0 0;
  }
  .switch-text{
    margin-bottom: -10px;
    position: relative;
    .text{
      position: absolute;
      left: 60px;
      top: 9px;
    }
  }
}