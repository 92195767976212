.labor-summary-wrap{
  .main-top-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    .main-title {
      display: flex;
      align-items: center;
      height: 57px;
      font-size: 18px;
    }
  }
  .filter-wrap {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 57px;
    z-index: 99;
    background-color: #fff;
    padding: 10px 0;
    margin-top: -10px;
    margin-bottom: -10px;
    align-items: center;
    .left-wrap {
      .employee-select {
        margin-left: 16px;

        .ant-select-selection-placeholder {
          color: #323232;
        }

        .ant-select-arrow {
          color: #5B5B5B;
        }
      }
    }

    .right-wrap {
      display: flex;
      gap: 0 10px;

      .ant-btn {
        border: 0;
      }
    }
  }

  .table-wrap {
    .card-table{
      margin-top: 16px;
      background-color: #fff;
    }
    .employee-name{
      color: #000;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .tips-box{
      overflow: hidden;
      overflow-x: scroll;
      padding-top: 16px;
      margin-top: 15;
      border-bottom: 1px solid #ddd;
      .table-box{
        min-width: 100%;
        display: table;
        white-space: nowrap;
      }
      .item{
        display: inline-block;
        width: 20%;
        min-width: 100px;
        text-align: center;
        line-height: 56px;
        font-weight: 600;
        .title{
          white-space: nowrap;
          color: #7d7d81;
          background-color: #f5f5f5;
          padding: 0 10px;
          border-bottom: 1px solid #e8e8e8;
        }
        .info{
          padding: 0 10px;
        }
        @media (max-width: 767px) {
          width: auto;
          text-align: left;
        }
      }
    }
    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
    .summary-row-gray-bgc{
      background-color: #efefef;
      font-weight: 600;
    }
  }
  .empty-style{
    margin-top: 100px;
  }
  @media (max-width: 767px) {
    margin: 0 -15px;
    .main-wrap{
      margin-top: 0;
      padding: 10px;
      border-radius: 0;
    }
    .filter-wrap {
      top: 49px;
      .left-wrap .employee-select {
        margin-left: 0;
        margin-top: 16px;
      }
    }
    .table-wrap{
      .employee-name{
        margin-bottom: 10px;
        font-size: 16px;
      }
      .card-table{
        padding: 10px;
      }
    }
  }
  @media (max-width: 440px) {
    .filter-wrap{
      display: block;
      top: 46px;
      .right-wrap{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .table-wrap .tips-box .item{
      line-height: 35px;
    }
  }
}




// ------- 打印样式 -------
@media print {

  .print-container {
    padding: 20px;

    .ant-table-wrapper table {
      width: 100% !important;
    }
    .ant-table-wrapper {
      overflow: hidden !important;
    }

    .ant-table-content {
      overflow: hidden !important;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;

      .ant-empty-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-empty-description {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

}