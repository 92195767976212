.employee-checkout-report{

  .sticky-row {
    position: sticky;
    bottom: 0;
    td {
      background: #F5F5F5 !important;
    }
  }
  .filter-wrap {
    position: sticky;
    top: 57px;
    z-index: 99;
    background-color: #fff;
    padding: 10px 0;
  }
  .ant-table-cell{
    max-width: 350px;
  }
  @media (max-width: 767px) {
    .filter-wrap {
      top: 49px;
    }
  }
  @media (max-width: 440px) {
    .filter-wrap {
      top: 46px;
    }
  }
}