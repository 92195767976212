.sales-categories {
    &-wrap {}

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0 24px 0;
        height: 64px;
        border-bottom: 1px solid #dddddd;

        &-title {
            font-size: 18px;
        }
    }

    &-body {
        margin-top: 25px;

        .ant-table-cell {
            &:nth-child(1) {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.ant-modal-body{
    .ant-form-item {
        margin-bottom: 24px !important;
    }
}