.cash-discounts-program-wrap {
  .main-top-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    .main-title {
      display: flex;
      align-items: center;
      height: 57px;
      font-size: 18px;
    }
  }

  .filter-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .left-wrap {
      .employee-select {
        margin-left: 16px;

        .ant-select-selection-placeholder {
          color: #323232;
        }

        .ant-select-arrow {
          color: #5B5B5B;
        }
      }
    }

    .right-wrap {
      display: flex;
      align-items: center;
    }
  }

  .table-wrap {
    margin-top: 16px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
    .ant-table-column-sort{
      background-color: white;
    }
    .total-row{
      background: #FAFAFA;
      font-weight: 500;
    }
  }

  .action-wrap {
    position: fixed;
    right: 85px;
    top: 80px;
    z-index: 20;
  }
  @media (max-width: 767px) {
    .action-wrap{
      top: 65px;
      right: 15px;
    }
  }
}