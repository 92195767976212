.product-mix-wrap {
  padding-bottom: 22px;

  .product-mix-title-wrap {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 72px;
    font-size: 18px;
    padding-bottom: 16px;
    .product-mix-title {
      height: 100%;
      display: flex;
      background-color: #fff;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }

  .content-wrap {
    padding: 22px;
    background: #F5F5F5;
    border-radius: 8px;
    .filter-wrap {
      display: flex;
      justify-content: space-between;
      position: sticky;
      top: 60px;
      z-index: 99;
      background-color: #F5F5F5;
      padding: 10px 0;
      margin-top: -10px;
      .filter-right-wrap {
        display: flex;
        gap: 0 10px;

        .ant-btn {
          border: 0;
        }
      }
    }

    .product-mix-split-wrap {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 0 -15px -14px;
    .m-title{
      margin-bottom: 0;
      .title{
        padding-left: 20px;
      }
    }
    .content-wrap {
      padding: 10px;
      border-radius: 0;
      .filter-wrap {
        top: 40px;
      }
      .product-mix-split-wrap {
        grid-template-columns: 1fr;
      }
      .top-sub-menus-list{
        padding: 8px;
        .top-sub-menus-list-table-wrap{
          margin-top: 8px;
        }
      }
    }
    .top-sub-menus-list .top-sub-menus-list-header-wrap .top-sub-menus-list-title-wrap,
    .break-down-by-menu-header-wrap .break-down-by-menu-title-wrap{
      font-size: 16px;
    }
  }
}




// ------- 打印样式 -------
@media print {
  .print-container {
    padding: 20px; 

    .ant-table-wrapper table {
      width: 100% !important;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;

      .ant-empty-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-empty-description {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}